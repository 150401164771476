import React from "react"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import HeroBanner from "../../components/HeroBanner"
import { ReactAudioPlayer } from "../../components/reactPlayer"
import { HeroText, HeroHeader, HeroDescription } from "../../styledComponents/heroBanner"
import {StyledBlockQuote} from "../../styledComponents/base"

import EventList from "../../components/EventList"
import {
  Section,
  SectionHeading
} from "../../styledComponents/section"


const Heading = ({ level = 1, location, children }) => (
  <SectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </SectionHeading>
)


const FreeQtTalk = ({ data, location }) => {
  return (
    <>
      <Layout location={location} isHomePage>
      <SEO title="Karina Grant - Energy healing" Description="Listen to Karina's FREE talk on Quantum-Touch" />
          <HeroBanner type="freeTalk">
            <HeroText>
              <HeroHeader>Quantum-Touch Introduction</HeroHeader>
              <HeroDescription>Date: 18th April 2022</HeroDescription>
              <StyledBlockQuote >
              <ReactAudioPlayer
                        autoPlay="false"
                        title="Talk introducing what Quantum-Touch is"
                        audioURL="https://karinagrant.s3.eu-west-1.amazonaws.com/free-qt-talk/free-talk-041822.m4a"
                    />
            </StyledBlockQuote> 
            </HeroText>
        </HeroBanner>
        
      </Layout>
    </>
  )
}

export default FreeQtTalk

export const pageQuery = graphql`
  query {
      allWpPost(sort: { fields: [date], order: DESC } limit: 5) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        isSticky
        categories {
          nodes {
            slug
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 975
                  quality: 100
                  placeholder: TRACED_SVG
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }
    }
}
`
